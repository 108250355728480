import { ArrowUpward } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useEffect, useState } from "react";

function scrollToTop(smooth: boolean = false) {
  if (smooth) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.documentElement.scrollTop = 0;
  }
}

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setVisible(document.documentElement.scrollTop >= 10);
    };
    onScroll();
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      {visible && (
        <Fab
          onClick={() => scrollToTop(true)}
          color="primary"
          aria-label="Scroll to top"
          sx={{
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        }}
        >
          <ArrowUpward />
        </Fab>
      )}
    </>
  );
};

export default ScrollToTop;
